import "./HeroSection.css";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-section-frame">
        <div className="hero-text-buttons">
          <div className="headline-subhead">
            <h1 className="discover-digital-art">The Manifesto</h1>
            <h3 className="in-a-future">
              in a future where web3 wars are mere legends, the year is 2140,
              and all Bitcoin has been mined. People have forgotten, but hidden
              treasures lie within ancient artifacts. These relics hold the key
              to a long-lost truth: there are two sides to every coin.
            </h3>
          </div>
          <button className="button9">
            <img
              className="rocketlaunch-icon4"
              alt=""
              src="/rocketlaunch.svg"
            />
            <div className="button10">Buy</div>
          </button>
          <div className="additional-info2">
            <div className="total-sale">
              <b className="button-text">88</b>
              <h3 className="supply">supply</h3>
            </div>
            <div className="auctions">
              <b className="b">0.1 ETH</b>
              <h3 className="price-bid">price bid</h3>
            </div>
            <div className="artists">
              <h2 className="eth">ETH</h2>
              <h3 className="blockchain">blockchain</h3>
            </div>
          </div>
        </div>
        <div className="highlighted-nft">
          <div className="artist-cardhorizontal-big">
            <div className="avatar">
              <img className="asset-12-2" alt="" src="/asset-12-2@2x.png" />
            </div>
            <div className="additional-info3">
              <div className="dish-studio">Dish Studio</div>
              <div className="total-sales-parent">
                <div className="total-sales">Total Sales:</div>
                <div className="eth1">34.53 ETH</div>
              </div>
            </div>
          </div>
          <img
            className="image-placeholder-icon"
            loading="lazy"
            alt=""
            src="/image-placeholder@2x.png"
          />
          <div className="the-manifesto-wrapper">
            <div className="the-manifesto">The Manifesto</div>
          </div>
          <div className="frame-parent">
            <div className="frame-group">
              <div className="price-wrapper">
                <div className="price">Price:</div>
              </div>
              <div className="eth-wrapper">
                <div className="eth2">1.63 ETH</div>
              </div>
            </div>
            <div className="frame-container">
              <div className="release-date-wrapper">
                <div className="release-date">Release Date:</div>
              </div>
              <div className="sep-2022-wrapper">
                <div className="sep-2022">2 Sep 2022</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
