import React, {useState, useRef} from 'react'
import { useMemo } from "react";
import "./AuctionTimerCTA.css";



const AuctionTimerCTA = ({
  button,
  propWidth,
  propMinWidth,
  propAlignSelf,
  propPadding,
  propAlignSelf1,
  propAlignSelf2,
  auctionTimerCTAFlex,
  buttonDisplay,
  auctionTimerCTAOpacity,
}) => {
  const auctionTimerCTAStyle = useMemo(() => {
    
    return {
      width: propWidth,
      minWidth: propMinWidth,
      alignSelf: propAlignSelf,
      flex: auctionTimerCTAFlex,
      opacity: auctionTimerCTAOpacity,
    };
  }, [
    propWidth,
    propMinWidth,
    propAlignSelf,
    auctionTimerCTAFlex,
    auctionTimerCTAOpacity,
  ]);

  
const [days, setDays] = useState(0);
const [hours, setHours] = useState(0);
const [minutes, setMinutes] = useState(0);
const [seconds, setSeconds] = useState(0);

const deadline = "May, 21, 2024";

const getTime = () => {
  const time = Date.parse(deadline) - Date.now();

  setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
  setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
  setMinutes(Math.floor((time / 1000 / 60) % 60));
  setSeconds(Math.floor((time / 1000) % 60));
};



  const timerStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const minutesStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
    };
  }, [propAlignSelf1]);

  const secondsStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf2,
    };
  }, [propAlignSelf2]);

  const buttonStyle = useMemo(() => {
    return {
      display: buttonDisplay,
    };
  }, [buttonDisplay]);

  React.useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="auction-timer-cta" style={auctionTimerCTAStyle}>
      <div className="auction-timer" id="timer">
        <div className="auction-ends-in">Buy in:</div>
        <div className="timer" style={timerStyle}>
          <div className="hours">
            <b className="setup-your-wallet8">{hours < 10 ? "0" + hours : hours}</b>
            <div className="hours1">Hours</div>
          </div>
          <h2 className="card-details8">:</h2>
          <div className="minutes">
            <b className="b2">{minutes < 10 ? "0" + minutes : minutes}</b>
            <div className="minutes1" style={minutesStyle}>
              Minutes
            </div>
          </div>
          <h2 className="card-details9">:</h2>
          <div className="seconds">
            <b className="b3">{seconds < 10 ? "0" + seconds : seconds}</b>
            <div className="seconds1" style={secondsStyle}>
              Seconds
            </div>
          </div>
        </div>
      </div>
      <button className="button31">
        <img className="wallet-icon8" alt="" src="/wallet.svg" />
        <div className="button32" style={buttonStyle}>
          {button}
        </div>
      </button>
    </div>
  );
};

export default AuctionTimerCTA;
