import Tags from "./Tags";
import AuctionTimerCTA from "./AuctionTimerCTA";
import "./ArtistInfo1.css";


const ArtistInfo1 = () => {
  return (
    <section className="artist-info1" >
      <div className="artist-info-section1">
        <div className="nft-info5">
          <div className="nft-info6">
            <div className="headline-subhead3">
              <h1 className="artist-name1" >The Manifesto</h1>
              <div className="minted-on-sep1">Mint on Sep 30, 2024</div>
            </div>
            <div className="additional-info10">
              <div className="description2">
                <div className="description3">Description</div>
                <div className="with-each-digital-container1">
                  <p className="with-each-digital1">{`With each digital brushstroke, the art man weaves AI secrets and whispers from distant realms. `}</p>
                  <p className="blank-line4">&nbsp;</p>
                  <p className="every-pixel-harbors1">{`Every pixel harbors a tale, an enigma awaiting decipherment. Their photography unveils the imaginary’s diverse forms, from dreams to fantasies, guided by the eccentric muses of Web3. `}</p>
                  <p className="blank-line5">&nbsp;</p>
                  <p className="for-11-years1">{`For 11 years, they’ve toiled, starting from scratch, retouching over 10,000 photos to attain remarkable mastery. `}</p>
                  <p className="blank-line6">&nbsp;</p>
                  <p className="eschewing-conventions-their1">
                    Eschewing conventions, their discovery of this project
                    ignited a profound intuition—the possibility of a visibility
                    channel to manifest their art’s true essence in the
                    representation of coins- BTC, ETH, and BNB.
                  </p>
                  <p className="blank-line7">&nbsp;</p>
                  <p className="something-is-hidden1">
                    Something is hidden inside of pieces of art. Fool's Gold.
                    Unravel the mysteries that come digging too deep down the
                    Rabbit Hole...
                  </p>
                </div>
              </div>
              <div className="details3">
                <div className="road-map1">{`Road Map : `}</div>
                <div className="website-link6">
                  <img
                    className="rocketlaunch-icon7"
                    alt=""
                    src="/rocketlaunch-1.svg"
                  />
                  <div className="september-community1">
                    February - Community activation
                  </div>
                </div>
                <div className="website-link7">
                  <img
                    className="lockkey-icon3"
                    loading="lazy"
                    alt=""
                    src="/lockkey.svg"
                  />
                  <div className="october-first1">
                    March - First initial Airdrop
                  </div>
                </div>
                <div className="website-link8">
                  <img className="lockkey-icon4" alt="" src="/lockkey.svg" />
                  <div className="november-collection1">
                    April - collection opens on OpenSea
                  </div>
                </div>
                <div className="website-link9">
                  <img className="lockkey-icon5" alt="" src="/lockkey.svg" />
                  <div className="decembre-surprise-container1">
                    <p className="may-surprise1">
                      May - Surprise event tailor-made for those that found gold
                    </p>
                  </div>
                </div>
              </div>
              <div className="details4">
                <div className="details5">Details</div>
                <div className="website-link10">
                  <img
                    className="globe-icon2"
                    loading="lazy"
                    alt=""
                    src="/globe1.svg"
                  />
                  <div className="view-on-etherscan1">View on Etherscan</div>
                </div>
                <div className="website-link11">
                  <img className="globe-icon3" alt="" src="/globe1.svg" />
                  <div className="view-original1">View Original</div>
                </div>
              </div>
              <Tags />
            </div>
          </div>
          <AuctionTimerCTA button="Coming soon" />
        </div>
      </div>
    </section>
  );
};

export default ArtistInfo1;
