import NFTCardsRow from "./NFTCardsRow";
import "./MoreNFTsFromTheArtist.css";

const MoreNFTsFromTheArtist = () => {
  return (
    <section className="more-nfts-from-the-artist">
      <div className="section-headline-button">
        <div className="section-headline2">
          <h1 className="more-from-this">More from this artist</h1>
        </div>
        <div className="button19">
          <img className="arrowright-icon" alt="" src="/arrowright.svg" />
          <div className="button20">Go To Artist Page</div>
        </div>
      </div>
      <NFTCardsRow
        imagePlaceholder="/image-placeholder-1@2x.png"
        imagePlaceholder1="/image-placeholder-2@2x.png"
        imagePlaceholder2="/image-placeholder-3@2x.png"
        nFTArtist={false}
      />
      <NFTCardsRow
        imagePlaceholder="/image-placeholder-4@2x.png"
        imagePlaceholder1="/image-placeholder-5@2x.png"
        imagePlaceholder2="/image-placeholder-6@2x.png"
        nFTArtist
        propWidth="unset"
        propHeight="22px"
        propFlex="1"
      />
      <NFTCardsRow
        imagePlaceholder="/image-placeholder-7@2x.png"
        imagePlaceholder1="/image-placeholder-8@2x.png"
        imagePlaceholder2="/image-placeholder-9@2x.png"
        nFTArtist
        propWidth="unset"
        propHeight="22px"
        propFlex="1"
      />
    </section>
  );
};

export default MoreNFTsFromTheArtist;
