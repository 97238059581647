import HeroSection1 from "../components/HeroSection1";
import ArtistInfo1 from "../components/ArtistInfo1";
import MoreNFTsFromTheArtist1 from "../components/MoreNFTsFromTheArtist1";
import "./HomepageTablet.css";

const HomepageTablet = () => {
  return (
    <div className="homepage-tablet">
      <header className="navigation">
        <div className="nav-logo">
          <div className="nav-logo1">
            <img
              className="logo-icon1"
              loading="lazy"
              alt=""
              src="/logo1.svg"
            />
          </div>
        </div>
        <div className="nav-nav-menu">
          <img
            className="burger-menu"
            loading="lazy"
            alt=""
            src="/burger-menu.svg"
          />
        </div>
      </header>
      <HeroSection1 />
      <ArtistInfo1 />
      <MoreNFTsFromTheArtist1 />
      <section className="how-it-works">
        <div className="section-headline">
          <h2 className="how-it-works1">How it works</h2>
          <div className="find-out-how">Find out how to get started</div>
        </div>
        <div className="card-row">
          <div className="info-card">
            <div className="wrapper-icon">
              <img className="icon" loading="lazy" alt="" src="/icon1.svg" />
            </div>
            <div className="card-details">
              <div className="setup-your-wallet">Setup your wallet</div>
              <div className="set-up-your">
                Install a digital wallet like MetaMask, set it up, and connect
                to the site by clicking the wallet icon on the top right.
              </div>
            </div>
          </div>
          <div className="info-card1">
            <div className="wrapper-icon1">
              <img className="icon1" alt="" src="/icon-11.svg" />
            </div>
            <div className="card-details1">
              <div className="setup-your-wallet1">Create collection</div>
              <div className="set-up-your1">
                Explore the NFT collection, choose one, and use your wallet to
                place a bid or complete the purchase.
              </div>
            </div>
          </div>
          <div className="info-card2">
            <div className="wrapper-icon2">
              <img className="icon2" alt="" src="/icon-21.svg" />
            </div>
            <div className="card-details2">
              <div className="setup-your-wallet2">Utilize your NFT</div>
              <div className="set-up-your2">
                Engage with the community by using your NFT in various
                applications, events, or as digital proof of ownership for
                assets or experiences.
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer1">
        <div className="footer-info1">
          <div className="nft-marketplace-info1">
            <img className="logo-icon2" alt="" src="/logo.svg" />
            <div className="additional-info1">
              <div className="community-info1">
                <div className="join-our-community1">Join our community</div>
                <div className="icons1">
                  <img
                    className="discordlogo-icon1"
                    loading="lazy"
                    alt=""
                    src="/discordlogo.svg"
                  />
                  <img
                    className="youtubelogo-icon1"
                    loading="lazy"
                    alt=""
                    src="/youtubelogo.svg"
                  />
                  <img
                    className="twitterlogo-icon1"
                    loading="lazy"
                    alt=""
                    src="/twitterlogo.svg"
                  />
                  <img
                    className="instagramlogo-icon1"
                    loading="lazy"
                    alt=""
                    src="/instagramlogo.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="explore2">
            <h3 className="explore3">Explore</h3>
            <div className="pages1">
              <div className="connect-a-wallet1">Connect a wallet</div>
            </div>
          </div>
          <div className="subscribe1">
            <h3 className="join-our-weekly1">Join our weekly digest</h3>
            <div className="subscribe-form-info1">
              <div className="get-exclusive-promotions1">{`Get exclusive promotions & updates straight to your inbox.`}</div>
              <div className="subscribe-form1">
                <div className="enter-your-email1">Enter your email here</div>
                <button className="button2">
                  <img
                    className="envelopesimple-icon1"
                    alt=""
                    src="/envelopesimple.svg"
                  />
                  <div className="button3">Subscribe</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-row1">
          <div className="divider1" />
        </div>
      </footer>
    </div>
  );
};

export default HomepageTablet;
