import HeroSection2 from "../components/HeroSection2";
import ArtistInfo2 from "../components/ArtistInfo2";
import MoreNFTsFromTheArtist2 from "../components/MoreNFTsFromTheArtist2";
import Footer from "../components/Footer";
import "./HomepageMobile.css";

const HomepageMobile = () => {
  return (
    <div className="homepage-mobile">
      <header className="navigation1">
        <div className="nav-logo2">
          <div className="nav-logo3">
            <img
              className="logo-icon3"
              loading="lazy"
              alt=""
              src="/logo1.svg"
            />
          </div>
        </div>
        <div className="nav-nav-menu1">
          <img
            className="burger-menu-icon"
            loading="lazy"
            alt=""
            src="/burger-menu.svg"
          />
        </div>
      </header>
      <HeroSection2 />
      <ArtistInfo2 />
      <MoreNFTsFromTheArtist2 />
      <section className="how-it-works2">
        <div className="section-headline1">
          <h2 className="how-it-works3">How it works</h2>
          <div className="find-out-how1">Find out how to get started</div>
        </div>
        <div className="card-row2">
          <div className="info-card3">
            <div className="wrapper-icon3">
              <img className="icon3" loading="lazy" alt="" src="/icon2.svg" />
            </div>
            <div className="card-details3">
              <div className="setup-your-wallet3">Setup your wallet</div>
              <div className="set-up-your3">
                Install a digital wallet like MetaMask, set it up, and connect
                to the site by clicking the wallet icon on the top right.
              </div>
            </div>
          </div>
          <div className="info-card4">
            <div className="wrapper-icon4">
              <img className="icon4" alt="" src="/icon-12.svg" />
            </div>
            <div className="card-details4">
              <div className="setup-your-wallet4">Create collection</div>
              <div className="set-up-your4">
                Explore the NFT collection, choose one, and use your wallet to
                place a bid or complete the purchase.
              </div>
            </div>
          </div>
          <div className="info-card5">
            <div className="wrapper-icon5">
              <img className="icon5" alt="" src="/icon-22.svg" />
            </div>
            <div className="card-details5">
              <div className="setup-your-wallet5">Utilize your NFT</div>
              <div className="set-up-your5">
                Engage with the community by using your NFT in various
                applications, events, or as digital proof of ownership for
                assets or experiences.
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HomepageMobile;
