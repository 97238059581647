import "./Navigation1.css";

const Navigation1 = () => {
  return (
    <header className="navigation2">
      <a href="#" class="buttonLogo" rel="noopener noreferrer">
      <div className="nav-logo4">
        <img className="logo-icon4" loading="lazy" alt="" src="/logo.svg" />
      </div>
      </a>
      <div className="nav-nav-menu2">
        <a href="#more" class="menuButtonCollection nodéco" rel="noopener noreferrer">
        <div className="marketplace">
          <img className="rocketlaunch-icon" alt="" src="/rocketlaunch.svg" />
          <div className="button4">The Collection</div>
        </div>
        </a>
        <a href="#manifesto"  class="menuButtonManifesto nodéco" rel="noopener noreferrer">
        <div className="rankings">
          <img className="rocketlaunch-icon1" alt="" src="/rocketlaunch.svg" />
          <div className="button5">The Manifesto</div>
        </div>
        </a>
        <a href="#" target="_blank" class="buttonCreateWallet nodéco" rel="noopener noreferrer">
        <div className="rankings1">
          <img className="rocketlaunch-icon2" alt="" src="/rocketlaunch.svg" />
          <div className="button6">Roadmap</div>
        </div>
        </a>
        {/*<div className="connect-a-wallet2">
          <img className="rocketlaunch-icon3" alt="" src="/rocketlaunch.svg" />
          <div className="button7">The Roadmap</div>
  </div>*/}
        <a href="https://learn.metamask.io/overview" target="_blank" class="buttonCreateWallet nodéco" rel="noopener noreferrer">
        <button className="sign-up">
          <img className="user-icon" alt="" src="/user.svg" />
          <div className="button8">Create Wallet</div>
        </button>
        </a>
      </div>
    </header>
  );
};

export default Navigation1;
