import HighlightedNFT from "./HighlightedNFT";
import "./HeroSection2.css";

const HeroSection2 = () => {
  return (
    <section className="hero-section2">
      <div className="hero-section-frame2">
        <div className="hero-text-buttons2">
          <div className="headline-subhead4">
            <h2 className="discover-digital-art2">The Manifesto</h2>
            <div className="in-a-future2">
              in a future where web3 wars are mere legends, the year is 2140,
              and all Bitcoin has been mined. People have forgotten, but hidden
              treasures lie within ancient artifacts. These relics hold the key
              to a long-lost truth: there are two sides to every coin.
            </div>
          </div>
          <HighlightedNFT
            asset122="/asset-12-2@2x.png"
            imagePlaceholder="/image-placeholder2@2x.png"
            propFlex="unset"
            propMinWidth="unset"
            propAlignSelf="stretch"
            propHeight="unset"
            propMaxHeight="100%"
            propGap="unset"
          />
          <button className="button35">
            <img
              className="rocketlaunch-icon8"
              alt=""
              src="/rocketlaunch.svg"
            />
            <div className="button36">Buy</div>
          </button>
          <div className="additional-info13">
            <div className="total-sale2">
              <b className="footer-info2">88</b>
              <div className="supply2">Supply</div>
            </div>
            <div className="auctions2">
              <b className="b4">0.1</b>
              <div className="price-bid2">Price bid</div>
            </div>
            <div className="artists2">
              <h3 className="eth11">ETH</h3>
              <div className="blockchain2">Blockchain</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection2;
