import "./Tags.css";

const Tags = () => {
  return (
    <div className="tags2">
      <div className="tags3">Tags</div>
      <div className="tags4">
        <button className="button23">
          <img className="wallet-icon4" alt="" src="/wallet.svg" />
          <div className="button24">Animation</div>
        </button>
        <button className="button25">
          <img className="wallet-icon5" alt="" src="/wallet.svg" />
          <div className="button26">illustration</div>
        </button>
        <button className="button27">
          <img className="wallet-icon6" alt="" src="/wallet.svg" />
          <div className="button28">moon</div>
        </button>
        <button className="button29">
          <img className="wallet-icon7" alt="" src="/wallet.svg" />
          <div className="button30">moon</div>
        </button>
      </div>
    </div>
  );
};

export default Tags;
