import InfoCard from "./InfoCard";
import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <section className="how-it-works4">
      <div className="section-headline3">
        <h1 className="how-it-works5">How it works</h1>
        <h3 className="find-out-how2">Find out how to get started</h3>
      </div>
      <div className="card-row3">
        <InfoCard
          icon="/icon.svg"
          setupYourWallet="Setup your wallet"
          setUpYourWalletOfChoiceCo="Install a digital wallet like MetaMask, set it up, and connect to the site by clicking the wallet icon on the top right."
        />
        <InfoCard
          icon="/icon-1.svg"
          setupYourWallet="Buy collection"
          setUpYourWalletOfChoiceCo="Explore the NFT collection, choose one, and use your wallet to place a bid or complete the purchase."
          propMinHeight="483px"
        />
        <div className="info-card7">
          <img className="icon7" loading="lazy" alt="" src="/icon-2.svg" />
          <div className="card-details7">
            <h3 className="setup-your-wallet7">Utilize your NFT</h3>
            <div className="set-up-your-container">
              <p className="engage-with-the">
                Engage with the community by using your NFT in various
                applications, events, or as digital proof of ownership for
                assets or experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
