import { useMemo } from "react";
import "./HighlightedNFT.css";

const HighlightedNFT = ({
  asset122,
  imagePlaceholder,
  frame230Cols,
  propFlex,
  propMinWidth,
  propAlignSelf,
  propHeight,
  propMaxHeight,
  propGap,
}) => {
  const highlightedNFTStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
      alignSelf: propAlignSelf,
    };
  }, [propFlex, propMinWidth, propAlignSelf]);

  const imagePlaceholderIconStyle = useMemo(() => {
    return {
      height: propHeight,
      maxHeight: propMaxHeight,
    };
  }, [propHeight, propMaxHeight]);

  const frameDivStyle = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  return (
    <div className="highlighted-nft1" style={highlightedNFTStyle}>
      <div className="artist-cardhorizontal-big1">
        <div className="avatar4">
          <img className="asset-12-24" alt="" src={asset122} />
        </div>
        <div className="additional-info8">
          <div className="dish-studio1">Dish Studio</div>
          <div className="total-sales-group">
            <div className="total-sales1">Total Sales:</div>
            <div className="eth6">34.53 ETH</div>
          </div>
        </div>
      </div>
      <img
        className="image-placeholder-icon4"
        loading="lazy"
        alt=""
        src={imagePlaceholder}
        style={imagePlaceholderIconStyle}
      />
      <textarea
        className="highlighted-nft-child"
        placeholder="The Manifesto"
        rows={5}
        cols={frame230Cols}
      />
      <div className="frame-div" style={frameDivStyle}>
        <div className="frame-parent1">
          <div className="price-container">
            <div className="price7">Price:</div>
          </div>
          <div className="eth-container">
            <div className="eth7">1.63 ETH</div>
          </div>
        </div>
        <div className="frame-parent2">
          <div className="release-date-container">
            <div className="release-date1">Release Date:</div>
          </div>
          <div className="sep-2022-container">
            <div className="sep-20221">2 Sep 2022</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighlightedNFT;
