import "./NFTCard.css";

const NFTCard = ({ imagePlaceholder, nFTName, asset122 }) => {
  return (
    <div className="nft-card5">
      <div className="image5">
        <img
          className="image-placeholder-icon7"
          alt=""
          src={imagePlaceholder}
        />
      </div>
      <div className="nft-info10">
        <div className="artst-info5">
          <h3 className="nft-name5">{nFTName}</h3>
          <div className="artist-avatar-name5">
            <div className="avatar7">
              <img className="asset-12-27" alt="" src={asset122} />
            </div>
            <div className="nft-artist5">Isaure</div>
          </div>
        </div>
        <div className="additional-info14">
          <div className="price12">
            <div className="price13">Price</div>
            <div className="eth12">1.63 ETH</div>
          </div>
          <div className="highest-bid10">
            <div className="highest-bid11">Highest Bid</div>
            <div className="weth5">0.33 wETH</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
