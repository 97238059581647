import HighlightedNFT from "./HighlightedNFT";
import "./HeroSection1.css";

const HeroSection1 = () => {
  return (
    <section className="hero-section1">
      <div className="hero-section-frame1">
        <div className="hero-text-buttons1">
          <div className="headline-subhead2">
            <h1 className="discover-digital-art1">The Manifesto</h1>
            <div className="in-a-future1">
              in a future where web3 wars are mere legends, the year is 2140,
              and all Bitcoin has been mined. People have forgotten, but hidden
              treasures lie within ancient artifacts. These relics hold the key
              to a long-lost truth: there are two sides to every coin.
            </div>
          </div>
          <button className="button21">
            <img
              className="rocketlaunch-icon6"
              alt=""
              src="/rocketlaunch.svg"
            />
            <div className="button22">Buy</div>
          </button>
          <div className="additional-info9">
            <div className="total-sale1">
              <b className="section-headline-button1">88</b>
              <div className="supply1">Supply</div>
            </div>
            <div className="auctions1">
              <b className="b1">{`0.1 `}</b>
              <div className="price-bid1">Price bid</div>
            </div>
            <div className="artists1">
              <h3 className="eth8">ETH</h3>
              <div className="blockchain1">Blockchain</div>
            </div>
          </div>
        </div>
        <HighlightedNFT
          asset122="/asset-12-2@2x.png"
          imagePlaceholder="/image-placeholder1@2x.png"
        />
      </div>
    </section>
  );
};

export default HeroSection1;
