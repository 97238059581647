import AuctionTimerCTA from "./AuctionTimerCTA";
import Tags from "./Tags";
import "./ArtistInfo2.css";

const ArtistInfo2 = () => {
  return (
    <section className="artist-info2" id="manifesto">
      <div className="nft-info9">
        <div className="headline-subhead5">
          <h2 className="artist-name2">The Manifesto</h2>
          <div className="minted-on-sep2">Mint on Sep 30, 2024</div>
        </div>
        <AuctionTimerCTA
          button="Coming soon"
          propWidth="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propPadding="0px var(--padding-11xs) 0px 0px"
          propAlignSelf1="stretch"
          propAlignSelf2="stretch"
          auctionTimerCTAFlex="unset"
          buttonDisplay="inline-block"
          auctionTimerCTAOpacity="unset"
        />
        <div className="description4">
          <div className="description5">Description</div>
          <div className="with-each-digital-container2">
            <p className="with-each-digital2">{`With each digital brushstroke, the art man weaves AI secrets and whispers from distant realms. `}</p>
            <p className="blank-line8">&nbsp;</p>
            <p className="every-pixel-harbors2">{`Every pixel harbors a tale, an enigma awaiting decipherment. Their photography unveils the imaginary’s diverse forms, from dreams to fantasies, guided by the eccentric muses of Web3. `}</p>
            <p className="blank-line9">&nbsp;</p>
            <p className="for-11-years2">{`For 11 years, they’ve toiled, starting from scratch, retouching over 10,000 photos to attain remarkable mastery. `}</p>
            <p className="blank-line10">&nbsp;</p>
            <p className="eschewing-conventions-their2">
              Eschewing conventions, their discovery of this project ignited a
              profound intuition—the possibility of a visibility channel to
              manifest their art’s true essence in the representation of coins-
              BTC, ETH, and BNB.
            </p>
            <p className="blank-line11">&nbsp;</p>
            <p className="something-is-hidden2">
              Something is hidden inside of pieces of art. Fool's Gold. Unravel
              the mysteries that come digging too deep down the Rabbit Hole...
            </p>
          </div>
        </div>
        <div className="details6">
          <div className="details7">
            <div className="road-map2">{`Road Map : `}</div>
            <div className="website-link12">
              <img
                className="rocketlaunch-icon9"
                alt=""
                src="/rocketlaunch-1.svg"
              />
              <div className="september-community2">
                February - Community activation
              </div>
            </div>
            <div className="website-link13">
              <img
                className="lockkey-icon6"
                loading="lazy"
                alt=""
                src="/lockkey.svg"
              />
              <div className="october-first2">
                March - First initial Airdrop
              </div>
            </div>
            <div className="website-link14">
              <img className="lockkey-icon7" alt="" src="/lockkey.svg" />
              <div className="november-collection2">
                April - collection opens on OpenSea
              </div>
            </div>
            <div className="website-link15">
              <img className="lockkey-icon8" alt="" src="/lockkey.svg" />
              <div className="decembre-surprise-container2">
                <p className="may-surprise2">
                  May - Surprise event tailor-made for those that found gold
                </p>
              </div>
            </div>
          </div>
          <div className="details8">Details</div>
          <div className="website-link16">
            <img
              className="globe-icon4"
              loading="lazy"
              alt=""
              src="/globe1.svg"
            />
            <div className="view-on-etherscan2">View on Etherscan</div>
          </div>
          <div className="website-link17">
            <img className="globe-icon5" alt="" src="/globe1.svg" />
            <div className="view-original2">View Original</div>
          </div>
        </div>
        <Tags />
      </div>
    </section>
  );
};

export default ArtistInfo2;
