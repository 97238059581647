import { useEffect } from "react";
import Navigation1 from "../components/Navigation1";
import HeroSection from "../components/HeroSection";
import ArtistInfo from "../components/ArtistInfo";
import MoreNFTsFromTheArtist from "../components/MoreNFTsFromTheArtist";
import HowItWorks from "../components/HowItWorks";
import "./HomepageDesktop.css";

const HomepageDesktop = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="homepage-desktop" data-animate-on-scroll>
      <Navigation1 />
      <HeroSection />
      <ArtistInfo />
      <MoreNFTsFromTheArtist />
      <HowItWorks />
      <footer className="footer">
        <div className="footer-info">
          <div className="nft-marketplace-info">
            <img className="logo-icon" loading="lazy" alt="" src="/logo.svg" />
            <div className="additional-info">
              <div className="community-info">
                <div className="join-our-community">Join our community</div>
                <div className="icons">
                  <img
                    className="discordlogo-icon"
                    loading="lazy"
                    alt=""
                    src="/discordlogo.svg"
                  />
                  <img
                    className="youtubelogo-icon"
                    loading="lazy"
                    alt=""
                    src="/youtubelogo.svg"
                  />
                  <img
                    className="twitterlogo-icon"
                    loading="lazy"
                    alt=""
                    src="/twitterlogo.svg"
                  />
                  <img
                    className="instagramlogo-icon"
                    loading="lazy"
                    alt=""
                    src="/instagramlogo.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="explore">
            <h3 className="explore1">Explore</h3>
            <div className="pages">
              <div className="connect-a-wallet">Connect a wallet</div>
            </div>
          </div>
          <div className="subscribe">
            <h3 className="join-our-weekly">Join our weekly digest</h3>
            <div className="subscribe-form-info">
              <div className="get-exclusive-promotions">{`Get exclusive promotions & updates straight to your inbox.`}</div>
              <div className="subscribe-form">
                <div className="enter-your-email">Enter your email here</div>
                <button className="button">
                  <img
                    className="envelopesimple-icon"
                    alt=""
                    src="/envelopesimple.svg"
                  />
                  <div className="button1">Subscribe</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-divider">
          <div className="divider" />
        </div>
      </footer>
    </div>
  );
};

export default HomepageDesktop;
