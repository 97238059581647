import NFTCardsRow1 from "./NFTCardsRow1";
import "./MoreNFTsFromTheArtist1.css";

const MoreNFTsFromTheArtist1 = () => {
  return (
    <section className="more-nfts-from-the-artist1">
      <div className="section-headline-button2">
        <div className="section-headline4">
          <h2 className="more-from-this1">More from this artist</h2>
        </div>
        <div className="button33">
          <img className="arrowright-icon1" alt="" src="/arrowright.svg" />
          <div className="button34">Go To Artist Page</div>
        </div>
      </div>
      <NFTCardsRow1
        imagePlaceholder="/image-placeholder-11@2x.png"
        imagePlaceholder1="/image-placeholder-21@2x.png"
      />
      <NFTCardsRow1
        imagePlaceholder="/image-placeholder-31@2x.png"
        imagePlaceholder1="/image-placeholder-41@2x.png"
      />
      <NFTCardsRow1
        imagePlaceholder="/image-placeholder-5@2x.png"
        imagePlaceholder1="/image-placeholder-61@2x.png"
      />
    </section>
  );
};

export default MoreNFTsFromTheArtist1;
