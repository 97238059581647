import "./Footer.css";

const Footer = () => {
  return (
    <section className="footer2">
      <div className="footer-info3">
        <div className="nft-marketplace-info2">
          <img className="logo-icon5" alt="" src="/logo.svg" />
          <div className="additional-info15">
            <div className="community-info2">
              <div className="join-our-community2">Join our community</div>
              <div className="icons2">
                <img
                  className="discordlogo-icon2"
                  loading="lazy"
                  alt=""
                  src="/discordlogo.svg"
                />
                <img
                  className="youtubelogo-icon2"
                  loading="lazy"
                  alt=""
                  src="/youtubelogo.svg"
                />
                <img
                  className="twitterlogo-icon2"
                  loading="lazy"
                  alt=""
                  src="/twitterlogo.svg"
                />
                <img
                  className="instagramlogo-icon2"
                  loading="lazy"
                  alt=""
                  src="/instagramlogo.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="explore4">
          <h3 className="explore5">Explore</h3>
          <div className="pages2">
            <div className="connect-a-wallet3">Connect a wallet</div>
          </div>
        </div>
        <div className="subscribe2">
          <h3 className="join-our-weekly2">Join our weekly digest</h3>
          <div className="subscribe-form-info2">
            <div className="get-exclusive-promotions2">{`Get exclusive promotions & updates straight to your inbox.`}</div>
            <div className="subscribe-widgetmobile">
              <div className="type-form">
                <img className="user-icon1" alt="" src="/user.svg" />
                <div className="enter-your-email2">
                  Enter Your Email Address
                </div>
                <img className="eyeslash-icon" alt="" src="/eyeslash.svg" />
              </div>
              <button className="button37">
                <img
                  className="envelopesimple-icon2"
                  alt=""
                  src="/envelopesimple.svg"
                />
                <div className="button38">Subscribe</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="divider-line">
        <div className="divider2" />
      </div>
    </section>
  );
};

export default Footer;
