import { useMemo } from "react";
import "./InfoCard.css";

const InfoCard = ({
  icon,
  setupYourWallet,
  setUpYourWalletOfChoiceCo,
  propMinHeight,
}) => {
  const infoCardStyle = useMemo(() => {
    return {
      minHeight: propMinHeight,
    };
  }, [propMinHeight]);

  return (
    <div className="info-card6" style={infoCardStyle}>
      <img className="icon6" loading="lazy" alt="" src={icon} />
      <div className="card-details6">
        <h3 className="setup-your-wallet6">{setupYourWallet}</h3>
        <div className="set-up-your6">{setUpYourWalletOfChoiceCo}</div>
      </div>
    </div>
  );
};

export default InfoCard;
