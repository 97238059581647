import { useMemo } from "react";
import "./NFTCardsRow.css";

const NFTCardsRow = ({
  imagePlaceholder,
  imagePlaceholder1,
  imagePlaceholder2,
  nFTArtist,
  propWidth,
  propHeight,
  propFlex,
}) => {
  const nFTArtistStyle = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
      flex: propFlex,
    };
  }, [propWidth, propHeight, propFlex]);

  return (
    <div className="nft-cards-row">
      <div className="nft-card">
        <div className="image">
          <img
            className="image-placeholder-icon1"
            loading="lazy"
            alt=""
            src={imagePlaceholder}
          />
        </div>
        <div className="nft-info2">
          <div className="artst-info">
            <h3 className="nft-name">Distant Galaxy</h3>
            <div className="artist-avatar-name">
              <div className="avatar1">
                <img
                  className="asset-12-21"
                  alt=""
                  src="/asset-12-2-1@2x.png"
                />
              </div>
              {!nFTArtist && (
                <div className="nft-artist" style={nFTArtistStyle}>
                  Isaure
                </div>
              )}
            </div>
          </div>
          <div className="additional-info5">
            <div className="price1">
              <div className="price2">Price</div>
              <div className="eth3">1.63 ETH</div>
            </div>
            <div className="highest-bid">
              <div className="highest-bid1">Highest Bid</div>
              <div className="weth">0.33 wETH</div>
            </div>
          </div>
        </div>
      </div>
      <div className="nft-card1">
        <div className="image1">
          <img
            className="image-placeholder-icon2"
            loading="lazy"
            alt=""
            src={imagePlaceholder1}
          />
        </div>
        <div className="nft-info3">
          <div className="artst-info1">
            <h3 className="nft-name1">Life On Edena</h3>
            <div className="artist-avatar-name1">
              <div className="avatar2">
                <img
                  className="asset-12-22"
                  alt=""
                  src="/asset-12-2-2@2x.png"
                />
              </div>
              <div className="nft-artist1">Isaure</div>
            </div>
          </div>
          <div className="additional-info6">
            <div className="price3">
              <div className="price4">Price</div>
              <div className="eth4">1.63 ETH</div>
            </div>
            <div className="highest-bid2">
              <div className="highest-bid3">Highest Bid</div>
              <div className="weth1">0.33 wETH</div>
            </div>
          </div>
        </div>
      </div>
      <div className="nft-card2">
        <div className="image2">
          <img
            className="image-placeholder-icon3"
            loading="lazy"
            alt=""
            src={imagePlaceholder2}
          />
        </div>
        <div className="nft-info4">
          <div className="artst-info2">
            <h3 className="nft-name2">AstroFiction</h3>
            <div className="artist-avatar-name2">
              <div className="avatar3">
                <img
                  className="asset-12-23"
                  alt=""
                  src="/asset-12-2-3@2x.png"
                />
              </div>
              <div className="nft-artist2">Isaure</div>
            </div>
          </div>
          <div className="additional-info7">
            <div className="price5">
              <div className="price6">Price</div>
              <div className="eth5">1.63 ETH</div>
            </div>
            <div className="highest-bid4">
              <div className="highest-bid5">Highest Bid</div>
              <div className="weth2">0.33 wETH</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCardsRow;
