import NFTCard from "./NFTCard";
import "./MoreNFTsFromTheArtist2.css";

const MoreNFTsFromTheArtist2 = () => {
  return (
    <section className="more-nfts-from-the-artist2">
      <div className="more-from-artist">
        <h2 className="more-from-this2">More from this artist</h2>
        <div className="nft-cards-row2">
          <NFTCard
            imagePlaceholder="/image-placeholder-12@2x.png"
            nFTName="Distant Galaxy"
            asset122="/asset-12-2-1@2x.png"
          />
          <NFTCard
            imagePlaceholder="/image-placeholder-22@2x.png"
            nFTName="Life On Edena"
            asset122="/asset-12-2-2@2x.png"
          />
          <NFTCard
            imagePlaceholder="/image-placeholder-32@2x.png"
            nFTName="Life On Edena"
            asset122="/asset-12-2-2@2x.png"
          />
          <NFTCard
            imagePlaceholder="/image-placeholder-42@2x.png"
            nFTName="Life On Edena"
            asset122="/asset-12-2-2@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

export default MoreNFTsFromTheArtist2;
