import "./NFTCardsRow1.css";

const NFTCardsRow1 = ({ imagePlaceholder, imagePlaceholder1 }) => {
  return (
    <div className="nft-cards-row1">
      <div className="nft-card3">
        <div className="image3">
          <img
            className="image-placeholder-icon5"
            alt=""
            src={imagePlaceholder}
          />
        </div>
        <div className="nft-info7">
          <div className="artst-info3">
            <h3 className="nft-name3">Distant Galaxy</h3>
            <div className="artist-avatar-name3">
              <div className="avatar5">
                <img
                  className="asset-12-25"
                  alt=""
                  src="/asset-12-2-1@2x.png"
                />
              </div>
              <div className="nft-artist3">Isaure</div>
            </div>
          </div>
          <div className="additional-info11">
            <div className="price8">
              <div className="price9">Price</div>
              <div className="eth9">1.63 ETH</div>
            </div>
            <div className="highest-bid6">
              <div className="highest-bid7">Highest Bid</div>
              <div className="weth3">0.33 wETH</div>
            </div>
          </div>
        </div>
      </div>
      <div className="nft-card4">
        <div className="image4">
          <img
            className="image-placeholder-icon6"
            alt=""
            src={imagePlaceholder1}
          />
        </div>
        <div className="nft-info8">
          <div className="artst-info4">
            <h3 className="nft-name4">Life On Edena</h3>
            <div className="artist-avatar-name4">
              <div className="avatar6">
                <img
                  className="asset-12-26"
                  alt=""
                  src="/asset-12-2-2@2x.png"
                />
              </div>
              <div className="nft-artist4">Isaure</div>
            </div>
          </div>
          <div className="additional-info12">
            <div className="price10">
              <div className="price11">Price</div>
              <div className="eth10">1.63 ETH</div>
            </div>
            <div className="highest-bid8">
              <div className="highest-bid9">Highest Bid</div>
              <div className="weth4">0.33 wETH</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCardsRow1;
