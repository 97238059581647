import AuctionTimerCTA from "./AuctionTimerCTA";
import "./ArtistInfo.css";

const ArtistInfo = () => {
  return (
    <section className="artist-info" id="manifesto">
      <div className="artist-info-section">
        <div className="nft-info">
          <div className="nft-info1">
            <div className="headline-subhead1">
              <h1 className="artist-name">The Manifesto</h1>
              <div className="minted-on-sep">Mint on Sep 30, 2024</div>
            </div>
            <div className="additional-info4">
              <div className="description">
                <h3 className="description1">Description</h3>
                <h3 className="with-each-digital-container">
                  <p className="with-each-digital">{`With each digital brushstroke, the art man weaves AI secrets and whispers from distant realms. `}</p>
                  <p className="blank-line">&nbsp;</p>
                  <p className="every-pixel-harbors">{`Every pixel harbors a tale, an enigma awaiting decipherment. Their photography unveils the imaginary’s diverse forms, from dreams to fantasies, guided by the eccentric muses of Web3. `}</p>
                  <p className="blank-line1">&nbsp;</p>
                  <p className="for-11-years">{`For 11 years, they’ve toiled, starting from scratch, retouching over 10,000 photos to attain remarkable mastery. `}</p>
                  <p className="blank-line2">&nbsp;</p>
                  <p className="eschewing-conventions-their">
                    Eschewing conventions, their discovery of this project
                    ignited a profound intuition—the possibility of a visibility
                    channel to manifest their art’s true essence in the
                    representation of coins- BTC, ETH, and BNB.
                  </p>
                  <p className="blank-line3">&nbsp;</p>
                  <p className="something-is-hidden">
                    Something is hidden inside of pieces of art. Fool's Gold.
                    Unravel the mysteries that come digging too deep down the
                    Rabbit Hole...
                  </p>
                </h3>
              </div>
              <div className="details">
                <h3 className="road-map">{`Road Map : `}</h3>
                <div className="website-link">
                  <img
                    className="rocketlaunch-icon5"
                    loading="lazy"
                    alt=""
                    src="/rocketlaunch-1.svg"
                  />
                  <h3 className="september-community">
                    February - Community activation
                  </h3>
                </div>
                <div className="website-link1">
                  <img
                    className="lockkey-icon"
                    loading="lazy"
                    alt=""
                    src="/lockkey.svg"
                  />
                  <h3 className="october-first">
                    March - First initial Airdrop
                  </h3>
                </div>
                <div className="website-link2">
                  <img
                    className="lockkey-icon1"
                    loading="lazy"
                    alt=""
                    src="/lockkey.svg"
                  />
                  <h3 className="november-collection">
                    April - collection opens on OpenSea
                  </h3>
                </div>
                <div className="website-link3">
                  <img
                    className="lockkey-icon2"
                    loading="lazy"
                    alt=""
                    src="/lockkey.svg"
                  />
                  <h3 className="decembre-surprise-container">
                    <p className="may-surprise">
                      May - Surprise event tailor-made for those that found gold
                    </p>
                  </h3>
                </div>
              </div>
              <div className="details1">
                <h3 className="details2">Details</h3>
                <div className="website-link4">
                  <img
                    className="globe-icon"
                    loading="lazy"
                    alt=""
                    src="/globe.svg"
                  />
                  <h3 className="view-on-etherscan">View on Etherscan</h3>
                </div>
                <div className="website-link5">
                  <img
                    className="globe-icon1"
                    loading="lazy"
                    alt=""
                    src="/globe.svg"
                  />
                  <h3 className="view-original">View Original</h3>
                </div>
              </div>
              <div className="tags">
                <h3 className="tags1">Tags</h3>
                <div className="w-e-t-h">
                  <button className="button11">
                    <img className="wallet-icon" alt="" src="/wallet.svg" />
                    <div className="button12">Animation</div>
                  </button>
                  <button className="button13">
                    <img className="wallet-icon1" alt="" src="/wallet.svg" />
                    <div className="button14">illustration</div>
                  </button>
                  <button className="button15">
                    <img className="wallet-icon2" alt="" src="/wallet.svg" />
                    <div className="button16">moon</div>
                  </button>
                  <button className="button17" id="more">
                    <img className="wallet-icon3" alt="" src="/wallet.svg" />
                    <div className="button18">moon</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <AuctionTimerCTA
            button="Place Bid"
            propWidth="unset"
            propMinWidth="unset"
            propAlignSelf="unset"
            propPadding="unset"
            propAlignSelf1="unset"
            propAlignSelf2="unset"
            auctionTimerCTAFlex="unset"
            buttonDisplay="inline-block"
            auctionTimerCTAOpacity="unset"
          />
        </div>
      </div>
    </section>
  );
};

export default ArtistInfo;
